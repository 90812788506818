<template>
  <div
    :class="[
      'text-field',
      { 'text-field--small': small },
    ]"
  >
    <div
      v-if="icon"
      class="text-field-icon"
    >
      <Icon :name="icon" />
    </div>

    <input
      ref="inputRef"
      :class="[
        'text-field-input',
        { 'text-field-input--has-icon': !!icon },
        { 'text-field-input--has-clear': !noClearButton },
      ]"
      type="text"
      :value="modelValue"
      :placeholder="placeholder"
      :readonly="readonly"
      @input="(e) => updateModelValue(e.target.value)"
      @focus="emitFocusEvent"
      @blur="emitUnfocusEvent"
      @click="emitClickEvent"
      autocomplete="off"
    >

    <Button
      v-if="!noClearButton"
      class="text-field-clear"
      :style="{ visibility: !modelValue ? 'hidden' : 'visible' }"
      icon="x"
      :small="small"
      @click="updateModelValue('')"
    />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import Icon from './Icon';
import Button from './Button';

export default {
  components: {
    Icon,
    Button,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noClearButton: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'focus',
    'unfocus',
    'click',
  ],
  setup(props, { emit }) {
    const inputRef = ref();
    const updateModelValue = (value) => {
      emit('update:modelValue', value);
    };
    const emitFocusEvent = () => {
      emit('focus');
    };
    const emitUnfocusEvent = () => {
      emit('unfocus');
    };
    const emitClickEvent = () => {
      emit('click');
    };

    onMounted(() => {
      if (props.autofocus) { inputRef.value.focus(); }
    });

    return {
      inputRef,
      updateModelValue,
      emitFocusEvent,
      emitUnfocusEvent,
      emitClickEvent,
    };
  },
};
</script>

<style lang="scss">
.text-field {
  position: relative;
  height: 44px;

  .text-field-icon {
    position: absolute;
    left: $textInputIconLeftOffset;
    top: 50%;
    width: $textInputIconSize;
    height: $textInputIconSize;
    transform: translateY(-50%);
    stroke: $gray500;
  }

  .text-field-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    padding: 0;
    stroke: $black;

    .icon {
      width: 14px;
      height: 14px;
      margin-right: 0;
    }
  }

  .text-field-input {
    background-color: $white;
    padding: $textInputPadding;
    border: $textInputBorder;
    border-radius: $textInputBorderRadius;
    width: 100%;
    height: 100%;
    transition: $textInputBoxShadowTransition;

    &.text-field-input--has-icon {
      padding-left: $textInputWithIconLeftPadding;
    }

    &.text-field-input--has-clear {
      padding-right: 48px;
    }

    &::placeholder {
      color: $textInputPlaceholderColor;
      user-select: none;
    }

    &:focus {
      box-shadow: $textInputFocusBoxShadow;
      outline: none;
    }
  }
}

.text-field.text-field--small {
  height: 33px;

  .text-field-clear {
    width: 33px;
    height: 33px;
  }

  .text-field-input {
    padding: $textInputSmallPadding;

    &.text-field-input--has-icon {
      padding-left: $textInputWithIconLeftPadding;
    }

    &.text-field-input--has-clear {
      padding-right: 37px;
    }
  }
}
</style>
