<template>
  <div
    :class="[
      'shot-clock',
      { 'shot-clock--active': isActive },
    ]"
  >
    <div
      v-show="isActive"
      class="shot-clock__progress"
      :style="`background: radial-gradient(closest-side, #E5EBEB 79%, transparent 80% 100%), conic-gradient(#003C3C ${percentage}%, #E5EBEB 0);`"
    />
    <Icon
      class="shot-clock__icon"
      :name="icon"
    />
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    percentage: {
      type: Number,
      default: 100,
      validator: (value) => value >= 0 && value <= 100,
    },
    icon: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shot-clock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  padding: 4px;
  border-radius: 100%;
  position: relative;
  background-color: #E5EBEB;
  opacity: 0.4000000059604645;
  overflow: hidden;

  &.shot-clock--active {
    opacity: 1;
  }
}

.shot-clock__progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}

.shot-clock__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 57%;
  height: 57%;
  transform: translate(-50%, -50%);
}
</style>
