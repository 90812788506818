<template>
  <div
    :class="[
      'football-timeout-indicators',
      { 'football-timeout-indicators--is-reversed': isReversed },
    ]"
  >
    <div
      v-for="index in totalTimeoutCount"
      :key="`${index},${isTimeoutActive(index)}`"
      :class="[
        'football-timeout-indicators__timeout',
        { 'football-timeout-indicators__timeout--is-active': isTimeoutActive(index) },
      ]"
    />
  </div>
</template>

<script>
import { includes } from 'lodash';
import { computed } from 'vue';

const OVERTIME_PERIODS = ['IN_OVERTIME'];

const CIRCLE_COUNT_IN_REGULAR_TIME = 3;

const CIRCLE_COUNT_IN_OVERTIME = 2;

export default {
  props: {
    timeoutsLeft: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    period: {
      type: String,
      default: '',
    },
    isReversed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isInOvertime = computed(() => includes(OVERTIME_PERIODS, props.period));
    const totalTimeoutCount = computed(() => (isInOvertime.value ? CIRCLE_COUNT_IN_OVERTIME : CIRCLE_COUNT_IN_REGULAR_TIME));

    const isTimeoutActive = (index) => index <= props.timeoutsLeft;

    return {
      isInOvertime,
      totalTimeoutCount,
      isTimeoutActive,
    };
  },
};
</script>

<style lang="scss">
.football-timeout-indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 23.24px;
  gap: 4px;

  &--is-reversed {
    flex-direction: row-reverse;
  }

  &__timeout {
    background-color: #fff;
    border: 2px solid #A9A9A9;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    user-select: none;

    &--is-active {
      background-color: #000;
      border-color: #000;
    }
  }
}
</style>
