<template>
  <div
    :class="[
      'tab',
      { 'tab--is-active': isActive },
    ]"
    @click="onClick"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: {},
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('click');
    };

    return {
      onClick,
    };
  },
};
</script>

<style lang="scss">
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: inset 0px -2px 0px #F0F0F0;
  padding: 0 8px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  white-space: nowrap;
  cursor: pointer;

  &--is-active {
    box-shadow: inset 0px -2px 0px #003C3C;
  }
}
</style>
