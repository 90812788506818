<template>
  <div
    :class="[
      'icon-button',
      { 'icon-button--small': size === 'small' },
      { 'icon-button--large': size === 'large' },
      { 'icon-button--disabled': disabled },
    ]"
    @click="click"
  >
    <Icon
      :name="icon"
    />
  </div>
</template>

<script>
import { includes } from 'lodash';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => includes(['small', 'medium', 'large'], value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: {},
  },
  setup(props, { emit }) {
    const click = () => {
      if (props.disabled) return;
      emit('click');
    };

    return {
      click,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 4px;

  &:not(.icon-button--disabled) {
    cursor: pointer;

    &:hover {
      background-color: #FAFAFA;
    }

    &:active {
      background-color: #F0F0F0;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}

.icon-button--small {
  width: 24px;
  height: 24px;
}

.icon-button--large {
  width: 44px;
  height: 44px;
}

.icon-button--disabled {
  color: #A9A9A9;
  stroke: #A9A9A9;

  .icon {
    opacity: 0.5;
  }
}
</style>
