<template>
  <div class="tab-group">
    <slot />
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">
.tab-group {
  display: flex;
  align-items: center;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: inset 0px -2px 0px #F0F0F0;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
