<template>
  <div class="market-tiers-table">
    <div
      :class="[
        'market-tiers-table__row',
        'market-tiers-table__row--header',
      ]"
    >
      <div class="market-tiers-table__cell">
        <div class="market-tiers-table__label">
          State
        </div>
      </div>
      <div
        v-for="column in columns"
        :key="column.id"
        class="market-tiers-table__cell"
      >
        <div class="market-tiers-table__label">
          {{ column.label }}
        </div>
      </div>
    </div>
    <div
      v-for="rule in rules"
      :key="rule.id"
      :class="[
        'market-tiers-table__row',
        'market-tiers-table__row--data',
      ]"
    >
      <div class="market-tiers-table__cell">
        <div class="market-tiers-table__state">
          <div
            v-if="showStatuses"
            :class="[
              'market-tiers-table__status',
              { 'market-tiers-table__status--active': rule.active },
            ]"
          />
          <div
            :class="[
              'market-tiers-table__label',
              { 'market-tiers-table__label--muted': rule.disabled },
            ]"
          >
            {{ rule.label }}
          </div>
        </div>
        <Tooltip
          v-if="rule.hint"
          class="market-tiers-table__tooltip"
          :text="rule.hint"
          left
        >
          <Icon
            name="info"
          />
        </Tooltip>
      </div>
      <div
        v-for="column in columns"
        :key="column.id"
        class="market-tiers-table__cell"
      >
        <MarketTiersInput
          :model-value="getTierValue(column.id, rule.field)"
          :disabled="rule.disabled"
          :has-no-value="!getTierValue(column.id, rule.field)"
          @update:model-value="(newValue) => updateTierValue(column.id, rule.field, newValue)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, every } from 'lodash';
import { computed } from 'vue';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import MarketTiersInput from './MarketTiersInput';

export default {
  components: {
    Tooltip,
    Icon,
    MarketTiersInput,
  },
  props: {
    tiers: {
      type: Object,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
      required: true,
    },
  },
  emits: {
    'update:tiers': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const showStatuses = computed(() => every(props.rules, (rule) => rule.active !== null));
    const getTierValue = (tierId, tierField) => (props.tiers ? props.tiers[tierId]?.[tierField] / 1000 : null);
    const updateTierValue = (tierId, tierField, newValue) => {
      const newTiers = cloneDeep(props.tiers);
      newTiers[tierId][tierField] = newValue * 1000;

      if (tierField === 'preMatch') {
        newTiers[tierId].inBreak = newTiers[tierId][tierField];
      }

      emit('update:tiers', newTiers);
    };

    return {
      showStatuses,
      getTierValue,
      updateTierValue,
    };
  },
};
</script>

<style lang="scss">
.market-tiers-table {
}

.market-tiers-table__row {
  display: grid;
  grid-template-columns: 115px 1fr 1fr 1fr 1fr;
  align-items: center;
  height: 33px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 8px;

  &.market-tiers-table__row--header {
    background: #FAFAFA;
    color: #6D6D6D;
  }

  &.market-tiers-table__row--data {
    background: #fff;
    color: #191414;

    .market-tiers-table__cell {
      border-bottom: 1px solid #F0F0F0;
    }
  }
}

.market-tiers-table__cell {
  display: flex;
  justify-content: space-between;

  .icon {
    max-width: 16px;
    max-height: 16px;
    min-width: 16px;
    min-height: 16px;
  }

  &:not(:first-of-type) {
    text-align: right;
    justify-content: flex-end;
  }
}

.market-tiers-table__label {
  padding: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.market-tiers-table__label--muted {
    color: #A9A9A9;
  }
}

.market-tiers-table__state {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;

  .market-tiers-table__label {
    padding: 0;
  }
}

.market-tiers-table__status {
  display: block;
  border-radius: 100px;
  border: 2px solid #CDCDCD;
  background: #F0F0F0;
  width: 12px;
  height: 12px;

  &.market-tiers-table__status--active {
    background: #00BC57;
    border-color: #007134;
  }
}

.market-tiers-table__tooltip {
  padding: 8px;

  .icon {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;

    svg {
      width: 100%;
      height: 100%;

      g {
        path {
          stroke: #CDCDCD;
        }
      }
    }
  }
}
</style>
