<template>
  <div
    :class="[
      'text-toggle-root',
      { 'text-toggle-root--is-disabled': disabled },
    ]"
  >
    <div
      v-for="item in toggleItems"
      :key="item.value"
      class="text-toggle-item"
      :class="{ 'text-toggle-item--active': modelValue === item.value }"
      @click="toggle(item.value)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggleItems: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const toggle = (newVal) => {
      if (props.disabled) return;
      emit('update:modelValue', newVal);
    };

    return {
      toggle,
    };
  },
};
</script>

<style lang="scss">
.text-toggle-root {
  display: flex;
  height: 32px;
  background-color: $white;
  position: relative;
  cursor: pointer;
  border: 1px solid $gray400;
  border-radius: 4px;
  text-transform: uppercase;

  .text-toggle-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px;
    &--active {
      background-color: $brandPrimary500;
      color: $white;
    }
  }

  &--is-disabled {
    background-color: $gray400;
    .text-toggle-item {
      background-color: $gray400;
      color: $gray700;
      cursor: not-allowed;
    }
  }
}
</style>
