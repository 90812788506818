<template>
  <div class="market-tiers-input">
    <div
      v-if="disabled || hasNoValue"
      class="market-tiers-input__label"
    >
      {{ modelValue }}
    </div>
    <input
      v-else
      :class="[
        'market-tiers-input__field',
        { 'market-tiers-input__field--error': !isInternalValueValid },
      ]"
      type="text"
      :value="internalValue"
      @input="onInput($event.target.value)"
      @change="onChange($event.target.value)"
    >
    <div
      v-if="!isInternalValueValid && !hasNoValue"
      class="market-tiers-input__error-info"
    >
      Value must be between {{ MIN_VALUE }} and {{ MAX_VALUE }}
    </div>
  </div>
</template>

<script>
import { isNaN } from 'lodash';
import { ref, computed, watch } from 'vue';

const MAX_VALUE = 1000; // * 1000 = value in currency (e.g. USD)
const MIN_VALUE = 0.001; // * 1000 = value in currency (e.g. USD)

export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasNoValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const internalValue = ref(0);
    const isInternalValueValid = computed(() => internalValue.value >= MIN_VALUE && internalValue.value <= MAX_VALUE);

    const onInput = (newValue) => {
      const oldValue = internalValue.value;

      if (isNaN(+newValue)) {
        internalValue.value = null;
        internalValue.value = oldValue;
        return;
      }

      internalValue.value = newValue;
    };

    const onChange = () => {
      if (isInternalValueValid.value) {
        emit('update:modelValue', internalValue.value);
        return;
      }

      internalValue.value = null;
      internalValue.value = props.modelValue;
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        internalValue.value = newValue;
      },
      { immediate: true },
    );

    return {
      MIN_VALUE,
      MAX_VALUE,
      internalValue,
      isInternalValueValid,
      onInput,
      onChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.market-tiers-input {
  display: flex;
  font: inherit;
  color: inherit;
  width: 100%;
  height: 100%;
  text-align: right;
  position: relative;
}

.market-tiers-input__label {
  padding: 8px;
  cursor: default;
  font: inherit;
  color: #A9A9A9;
  width: 100%;
  height: 100%;
}

.market-tiers-input__field {
  padding: 8px;
  position: relative;
  font: inherit;
  color: inherit;
  outline: none;
  width: 100%;
  height: 100%;
  text-align: right;

  &:focus-visible {
    outline: 1px solid #a2bfff;
    z-index: 10;
  }

  &.market-tiers-input__field--error {
    outline: 1px solid #FF2E2D;
    z-index: 9;
  }
}

.market-tiers-input__error-info {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: calc(100% + 8px);
  font-size: 12px;
  background-color: $black;
  color: $white;
  white-space: nowrap;
  padding: 0 12px;
  height: 38px;
  border-radius: 8px;
  left: 50%;
  transform: translate(-50%);
}
</style>
