<template>
  <div
    :class="[
      'play-by-play',
      { 'play-by-play--is-expanded': isExpanded }
    ]"
  >
    <div class="play-by-play__header">
      <span class="play-by-play__header-title">
        Play By Play
      </span>
      <span
        class="play-by-play__header-button"
        @click="toggleExpansion"
      >
        <Icon
          :name="toggleIcon"
        />
      </span>
    </div>
    <div
      ref="scrollerElement"
      class="play-by-play__content"
    >
      <div
        v-if="!actions.length"
        class="play-by-play__message"
      >
        Currently, no data available
      </div>
      <PlayByPlayAction
        v-for="action in displayedActions"
        :key="action.id"
        :message="action.message"
        :clock="action.clock"
      />
    </div>
    <div
      v-if="showScrollToTop"
      class="play-by-play__top-button"
      @click="scrollToTop"
    >
      <Icon
        name="arrow-up"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { take } from 'lodash';
import { useScroll } from '@vueuse/core';
import { useSquad } from '@/composables';
import { parsePlayByPlayActions, formatPlayByPlayActions } from '@/services/helpers/play-by-play';
import Icon from './Icon';
import PlayByPlayAction from './PlayByPlayAction';

const MAX_ACTIONS_COLLAPSED = 3;
const MAX_ACTIONS_EXPANDED = 10;

export default {
  components: {
    Icon,
    PlayByPlayAction,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const scrollerElement = ref(null);
    const { y: scrollY } = useScroll(scrollerElement);
    const showScrollToTop = computed(() => scrollY.value >= 40);
    const scrollToTop = () => {
      scrollerElement.value.scrollTo({ top: 0 });
    };

    const isExpanded = ref(false);
    const { squad } = useSquad(props.event);
    const actions = computed(() => parsePlayByPlayActions(props.event));
    const recentActions = computed(() => take(actions.value, MAX_ACTIONS_EXPANDED));
    const formattedActions = computed(() => formatPlayByPlayActions(recentActions.value, {
      event: props.event,
      squad: squad.value,
    }));
    const displayedActions = computed(() => take(formattedActions.value, (isExpanded.value ? MAX_ACTIONS_EXPANDED : MAX_ACTIONS_COLLAPSED)));

    const toggleIcon = computed(() => (isExpanded.value ? 'chevron-up' : 'chevron-down'));
    const toggleExpansion = () => {
      isExpanded.value = !isExpanded.value;
    };

    return {
      scrollerElement,
      showScrollToTop,
      scrollToTop,
      isExpanded,
      squad,
      actions,
      recentActions,
      formattedActions,
      displayedActions,
      toggleIcon,
      toggleExpansion,
    };
  },
};
</script>

<style lang="scss">
.play-by-play {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-medium, 4px);
  border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
  background: var(--neutral-bg-default-resting, #FFF);
  min-height: 136px;
  margin-top: 4px;
  position: relative;

  &--is-expanded {
    min-height: 276px;
    max-height: 276px;
    height: 276px;
    // overflow-y: auto;
  }

  &__header {
    display: flex;
    padding: 2px;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;
    border-radius: var(--radius-none, 0px);
    border-bottom: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-weak-resting, #FAFAFA);
    overflow: hidden;
    color: var(--neutral-text-medium-resting, #6D6D6D);
    text-overflow: ellipsis;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    position: relative;

    &-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &-button {
      margin-left: auto;
      cursor: pointer;

      .icon svg {
        stroke: #6D6D6D;
        max-width: 12px;
        max-height: 12px;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &__message {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutral-text-default-disabled, #A9A9A9);
    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__top-button {
    position: absolute;
    bottom: 28px;
    left: calc(100% - 24px - 16px);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #003C3C;
    padding: 7px;
    cursor: pointer;

    .icon svg path {
      fill: #fff;
    }
  }
}
</style>
