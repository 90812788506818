<template>
  <div class="selectable-edit-list">
    <div class="selectable-edit-list__filter">
      <div class="selectable-edit-list__search-filter">
        <TextInput
          v-model="searchQuery"
          icon="search"
          :placeholder="searchPlaceholder"
          small
        />
      </div>
    </div>
    <div class="selectable-edit-list__selection">
      <Checkbox
        class="selectable-edit-list__selection-checkbox"
        :model-value="isBulkSelectionChecked"
        :intermediate="isBulkSelectionIntermediate"
        @click="toggleBulkSelection"
      />
      <div class="selectable-edit-list__selection-text">
        {{ selectedItems.length }} selected
      </div>
    </div>
    <div class="selectable-edit-list__content">
      <div
        v-if="!filteredItems.length"
        class="selectable-edit-list__empty-state"
      >
        {{ emptyStatePlaceholder }}
      </div>
      <div
        v-for="item in filteredItems"
        :key="item.id"
        class="selectable-edit-list__item"
        @click.stop="toggleIsItemSelected(item)"
      >
        <Checkbox
          class="selectable-edit-list__item-checkbox"
          :model-value="isItemSelected(item)"
          @update:model-value="(isSelected) => setIsItemSelected(item, isSelected)"
        />
        <span class="selectable-edit-list__item-label">
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  filter,
  includes,
  isEqual,
  map,
  toLower,
  trim,
  uniqBy,
} from 'lodash';
import { computed, ref } from 'vue';
import TextInput from '@/components/common/TextInput';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    TextInput,
    Checkbox,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    emptyStatePlaceholder: {
      type: String,
      default: 'No data.',
    },
  },
  emits: {
    'update:selectedItems': {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const searchQuery = ref('');
    const selectedItemIds = computed(() => map(props.selectedItems, 'id'));
    const filteredItems = computed(() => filter(props.items, (item) => includes(toLower(item.name), toLower(trim(searchQuery.value)))));
    const filteredSelectedItems = computed(() => filter(filteredItems.value, (item) => includes(selectedItemIds.value, item.id)));

    // Bulk selection
    const isAnyItemSelectable = computed(() => !!filteredItems.value.length);
    const isAnyFilteredItemSelected = computed(() => !!filteredSelectedItems.value.length);
    const isEveryFilteredItemSelected = computed(() => isEqual(filteredItems.value, filteredSelectedItems.value));
    const isBulkSelectionChecked = computed(() => !!filteredItems.value.length && isAnyFilteredItemSelected.value);
    const isBulkSelectionIntermediate = computed(() => isAnyFilteredItemSelected.value && !isEveryFilteredItemSelected.value);

    const selectAllFilteredItems = () => {
      const newSelectedItems = uniqBy([...props.selectedItems, ...filteredItems.value], 'id');
      emit('update:selectedItems', newSelectedItems);
    };
    const unselectAllFilteredItems = () => {
      const filteredItemIds = map(filteredItems.value, 'id');
      const newSelectedItems = filter(props.selectedItems, (selectedItem) => !includes(filteredItemIds, selectedItem.id));
      emit('update:selectedItems', newSelectedItems);
    };
    const toggleBulkSelection = () => {
      if (isEveryFilteredItemSelected.value) {
        unselectAllFilteredItems();
      } else {
        selectAllFilteredItems();
      }
    };

    // Item selection
    const isItemSelected = (item) => includes(selectedItemIds.value, item.id);
    const setIsItemSelected = (item, newIsSelected) => {
      if (newIsSelected) {
        emit('update:selectedItems', [...props.selectedItems, item]);
      } else {
        emit('update:selectedItems', filter(props.selectedItems, (selectedItem) => selectedItem.id !== item.id));
      }
    };
    const toggleIsItemSelected = (item) => {
      setIsItemSelected(item, !isItemSelected(item));
    };

    return {
      searchQuery,
      selectedItemIds,
      filteredItems,
      filteredSelectedItems,

      isAnyItemSelectable,
      isAnyFilteredItemSelected,
      isEveryFilteredItemSelected,
      isBulkSelectionChecked,
      isBulkSelectionIntermediate,
      selectAllFilteredItems,
      unselectAllFilteredItems,
      toggleBulkSelection,

      isItemSelected,
      setIsItemSelected,
      toggleIsItemSelected,
    };
  },
};
</script>

<style lang="scss">
.selectable-edit-list {
  &__filter {
    padding: 0 8px 8px;
  }

  &__selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    padding: 0 8px 0 16px;
    background-color: #FAFAFA;
    border-bottom: 1px solid #F0F0F0;
    position: sticky;
    top: 0;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #A9A9A9;
  }

  &__content {
    height: 468px;
    min-height: 468px;
    max-height: 468px;
    overflow-y: auto;
    padding-top: 8px;
  }

  &__empty-state {
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    height: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 16px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    cursor: pointer;
  }
}
</style>
