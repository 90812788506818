<template>
  <div
    :class="['market-tiers', {'slim-content': showSlimTable}]"
  >
    <div class="market-tiers__header">
      <div class="market-tiers__header-column">
        <div class="market-tiers__header-title">
          <Heading
            as="h2"
            size="xs"
          >
            Market tiers
          </Heading>
        </div>
        <Tooltip
          text="Values in $k"
          right
        >
          <Icon
            class="market-tiers__header-icon"
            name="info"
          />
        </Tooltip>
      </div>
      <div
        v-if="id"
        class="market-tiers__header-column"
      >
        <Button
          variant="primary"
          :disabled="!containsChanges"
          @click="updateTiers"
        >
          Save
        </Button>
      </div>
    </div>
    <div class="market-tiers__content">
      <MarketTiersTable
        v-if="!showSlimTable"
        :tiers="tiers"
        :columns="tierColumns"
        :rules="stateRules"
        @update:tiers="setTiers"
      />
      <MarketTiersTableSlim
        v-if="showSlimTable"
        :event-id="id"
        :match-state="matchState"
        :limit-configuration="limitConfiguration"
        :in-prematch="inPrematch"
        :in-break="inBreak"
        @update:limit-configuration="({tiers}) => setTiers(tiers)"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { isEqual, cloneDeep } from 'lodash';
import { createTierColumns, createStateRules } from '@/services/helpers/market-tiers';
import { updateSportTemplateLimits } from '@/services/api';
import Heading from '@/components/common/Heading';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import MarketTiersTable from './MarketTiersTable';
import MarketTiersTableSlim from '../trading-ui/multiview/event-container/MarketTiersTableSlim';

export default {
  components: {
    Heading,
    Tooltip,
    Icon,
    Button,
    MarketTiersTable,
    MarketTiersTableSlim,
  },
  props: {
    matchState: {
      type: String,
      default: '',
    },
    limitConfiguration: {
      type: Object,
      required: true,
    },
    inPrematch: {
      type: Boolean,
      default: undefined,
    },
    inBreak: {
      type: Boolean,
      default: undefined,
    },
    hideIndicators: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    showSlimTable: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:limitConfiguration': {
      type: Object,
    },
    savedLimitConfiguration: {},
  },
  setup(props, { emit }) {
    const store = useStore();
    const tiers = ref(cloneDeep(props.limitConfiguration.tiers));
    const originalTiers = ref(cloneDeep(props.limitConfiguration.tiers));
    const tierColumns = computed(() => createTierColumns());
    const stateRules = computed(() => createStateRules(props));
    const containsChanges = ref(!isEqual(tiers.value, originalTiers.value));

    const setTiers = (newTiers) => {
      tiers.value = newTiers;
      containsChanges.value = !isEqual(tiers.value, originalTiers.value);
      emit('update:limitConfiguration', {
        ...props.limitConfiguration,
        tiers: tiers.value,
      });
    };
    const updateTiers = () => {
      const limitConfiguration = {
        ...props.limitConfiguration,
        tiers: tiers.value,
      };
      updateSportTemplateLimits({
        id: props.id,
        payload: limitConfiguration,
      }).then(() => {
        originalTiers.value = tiers.value;
        containsChanges.value = !isEqual(tiers.value, originalTiers.value);
        emit('savedLimitConfiguration', limitConfiguration);
        store.dispatch('addNotification', {
          message: 'Market tiers succesfully updated',
          type: 'success',
          duration: 5000,
        });
      }).catch((error) => {
        console.error(error);
        tiers.value = originalTiers.value;
        containsChanges.value = !isEqual(tiers.value, originalTiers.value);
        store.dispatch('addNotification', {
          message: 'Unable to update market tiers',
          type: 'error',
          duration: 5000,
        });
      });
    };

    watch(() => props.limitConfiguration, (newLimitValues) => {
      tiers.value = newLimitValues.tiers;
      originalTiers.value = newLimitValues.tiers;
    });

    return {
      tiers,
      originalTiers,
      tierColumns,
      stateRules,
      containsChanges,
      setTiers,
      updateTiers,
    };
  },
};
</script>

<style lang="scss">
.market-tiers {
  border-radius: 4px;
  border: 1px solid #DDD;
  background: #FFF;
  margin-bottom: 16px;
  min-height: 205px;

  .market-tiers__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  .market-tiers__header-column {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .market-tiers__header-title {
    color: #191414;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .market-tiers__header-icon {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;

    svg {
      width: 100%;
      height: 100%;

      g {
        path {
          stroke: #CDCDCD;
        }
      }
    }
  }

  .market-tiers__content {
    padding: 0 16px 16px;
  }

  &.slim-content {
    min-height: 125px;
  }
}
</style>
