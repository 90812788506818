<template>
  <div class="modal-with-details">
    <div class="modal-with-details__main-frame">
      <div class="modal-with-details__header">
        <div class="modal-with-details__header-title">
          {{ title }}
        </div>
        <div
          v-if="subtitle"
          class="modal-with-details__header-subtitle"
        >
          {{ subtitle }}
        </div>
      </div>
      <div class="modal-with-details__body">
        <slot />
      </div>
      <div class="modal-with-details__footer">
        <div
          class="modal-with-details__toggle-control"
          @click="setIsShowingDetails(!isShowingDetails)"
        >
          <Toggle
            :model-value="isShowingDetails"
            @update:model-value="setIsShowingDetails"
          />
          {{ showDetailsLabel }}
        </div>
        <div class="modal-with-details__button-group">
          <Button
            variant="tertiary"
            @click="dismiss"
          >
            {{ dismissButtonLabel }}
          </Button>
          <Button
            variant="primary"
            :loading="isSubmitButtonLoading"
            :disabled="isSubmitButtonDisabled"
            @click="submit"
          >
            {{ submitButtonLabel }}
          </Button>
        </div>
      </div>
      <div
        v-if="isShowingDetails"
        class="modal-with-details__aside-frame"
      >
        <slot name="details" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Toggle from '@/components/common/Toggle';
import Button from '@/components/common/Button';

export default {
  components: {
    Toggle,
    Button,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    dismissButtonLabel: {
      type: String,
      default: 'Dismiss',
    },
    submitButtonLabel: {
      type: String,
      default: 'Submit',
    },
    isSubmitButtonDisabled: {
      type: Boolean,
      default: false,
    },
    isSubmitButtonLoading: {
      type: Boolean,
      default: false,
    },
    showDetailsLabel: {
      type: String,
      default: 'Show details',
    },
  },
  emits: {
    dismiss: {
    },
    submit: {
    },
  },
  setup(props, { emit }) {
    const isShowingDetails = ref(false);
    const setIsShowingDetails = (newIsShowingDetails) => {
      isShowingDetails.value = newIsShowingDetails;
    };
    const dismiss = () => {
      emit('dismiss');
    };
    const submit = () => {
      emit('submit');
    };

    return {
      isShowingDetails,
      setIsShowingDetails,
      dismiss,
      submit,
    };
  },
};
</script>

<style lang="scss">
.modal-with-details {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modalZIndex;
  pointer-events: all;

  &__main-frame {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 6px;
    width: 640px;
  }

  &__aside-frame {
    display: flex;
    flex-direction: column;
    background: #FAFAFA;
    border: 1px solid #F0F0F0;
    color: #6D6D6D;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 6px;
    position: absolute;
    left: calc(100% + 8px);
    width: 248px;
    padding: 16px;
  }

  &__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
    height: 74px;
    min-height: 74px;
    max-height: 74px;
    border-bottom: 1px solid #F0F0F0;

    &-title {
      font-size: 15.72px;
      font-family: 'Poppins';
      font-weight: 600;
    }

    &-subtitle {
      font-size: 12px;
      line-height: 14px;
      color: $gray700;
    }
  }

  &__body {
    flex: 1;
    padding: 16px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    height: 64px;
    padding: 0 16px 0 20px;
    border-top: 1px solid #F0F0F0;
    gap: 16px;
  }

  &__toggle-control {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #191414;
    cursor: pointer;
  }

  &__button-group {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
