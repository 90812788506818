<template>
  <div class="slider">
    <div class="slider__container">
      <input
        class="slider__input"
        :class="{ 'disabled': disabled }"
        ref="input"
        type="range"
        :value="modelValue"
        @input="(e) => updateValue(e.target.value)"
        :min="min"
        :max="max"
        :step="step"
        :disabled="disabled"
      >
    </div>
  </div>
</template>

<script>
import { clamp, toNumber } from 'lodash';

const emitTypes = {
  UPDATE_MODEL_VALUE: 'update:modelValue',
};

export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    [emitTypes.UPDATE_MODEL_VALUE]: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const updateValue = (value) => {
      const newValue = clamp(toNumber(value), props.min, props.max);
      emit(emitTypes.UPDATE_MODEL_VALUE, newValue);
    };

    return {
      updateValue,
    };
  },
};
</script>

<style lang="scss">
.slider  {
  width: 100%;

  .slider__container {
    width: 100%;
  }

  .slider__input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: #CDCDCD;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      background: #fff;
      border: 2px solid #000;
      cursor: pointer;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      width: 18px;
      height: 18px;
      background: #fff;
      border: 2px solid #000;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}
</style>
